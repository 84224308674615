<template>
  <v-container>
    <v-row dense>
      <v-col class="implementation-title">
        <h5 class="subtitle-1">
          Realizar upload com as numerações de carteirinhas
        </h5>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              text
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="downloadFileBiobe"
            >
              Baixar layout
              <v-icon right color="primary"> fa-solid fa-download </v-icon>
            </v-btn>
          </template>
          <span>Baixar layout</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12">
        <small class="font-italic">
          Clique no botão <strong>"enviar arquivo"</strong> para realizar o
          upload e enviar para processamento.
        </small>
      </v-col>
    </v-row>

    <BaseTableList title="Listagem de arquivos">
      <template v-slot:table>
        <v-data-table
          item-key="id"
          dense
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="items"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="
                    item.situation === 'Processado com erros'
                      ? 'error'
                      : 'success'
                  "
                  small
                  @click="downloadDocument(item)"
                >
                  fa-solid fa-download
                </v-icon>
              </template>
              <span>Baixar arquivo</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton
          @click="back"
          outlined
          color="primary"
          title="Voltar"
          class="mr-2"
        />
        <BaseButtonUpload
          color="primary"
          title="Enviar arquivo"
          tooltip="Clique para subir o arquivo com as numerações de carteirinhas"
          @uploadFile="uploadFile($event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { ImplementationService } from '@/services/api/implementation';

export default {
  mixins: [rulesMixin],

  data: () => ({
    items: [],
    valid: true,
    headers: [
      { text: 'Arquivo', value: 'fileName' },
      { text: 'Data de envio', value: 'createdAt' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action' }
    ]
  }),

  props: {
    implementationId: {
      type: String
    }
  },

  created() {
    this.search();
  },

  methods: {
    formatDate,

    async search() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } =
          await implementationService.getImplementationCardFiles(
            this.implementationId
          );

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async uploadFile(item) {
      try {
        const implementationService = new ImplementationService();
        const { status } =
          await implementationService.uploadImplementationCardFile({
            implementationId: this.implementationId,
            file: item.file
          });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadFileBiobe() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } =
          await implementationService.getCardTemplateFile();

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadDocument(item) {
      try {
        const implementationService = new ImplementationService();
        const { status, data } = await implementationService.downloadCardFile(
          item.id
        );

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    }
  }
};
</script>
